import {
  GET_RETTUNGSDIENSTFORTBILDUNG,
  GET_RETTUNGSDIENSTFORTBILDUNGEN,
  RETTUNGSDIENSTFORTBILDUNG_DELETED,
  RETTUNGSDIENSTFORTBILDUNG_ERROR
} from '../actions/types';

const initialState = {
  rettungsdienstfortbildungen: [],
  rettungsdienstfortbildung: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_RETTUNGSDIENSTFORTBILDUNGEN:
      return {
        ...state,
        rettungsdienstfortbildungen: payload,
        loading: false
      };
    case GET_RETTUNGSDIENSTFORTBILDUNG:
      return {
        ...state,
        rettungsdienstfortbildung: payload,
        loading: false
      };
    case RETTUNGSDIENSTFORTBILDUNG_DELETED:
      return {
        ...state,
        loading: false
      };
    case RETTUNGSDIENSTFORTBILDUNG_ERROR:
    default:
      return state;
  }
}
