import { combineReducers } from 'redux';
import alert from './alert';
import ausbildung from './ausbildung';
import auth from './auth';
import blutspende from './blutspende';
import changelog from './changelog';
import config from './config';
import dienstabend from './dienstabend';
import einsatzbericht from './einsatzbericht';
import fahrzeug from './fahrzeug';
import fahrzeugbelegung from './fahrzeugbelegung';
import kleidung from './kleidung';
import lehrgang from './lehrgang';
import melder from './melder';
import newsletter from './newsletter';
import ort from './ort';
import raumbelegung from './raumbelegung';
import rettungsdienstfortbildung from './rettungsdienstfortbildung';
import profile from './profile';
import sanitaetsdienst from './sanitaetsdienst';
import schluessel from './schluessel';
import sonntagKTW from './sonntagKTW';
import stundennachweis from './stundennachweis';
import veranstaltung from './veranstaltung';
import wochenKTW from './wochenKTW';

export default combineReducers({
  alert,
  ausbildung,
  auth,
  blutspende,
  changelog,
  config,
  dienstabend,
  einsatzbericht,
  fahrzeug,
  fahrzeugbelegung,
  kleidung,
  lehrgang,
  melder,
  newsletter,
  ort,
  profile,
  raumbelegung,
  rettungsdienstfortbildung,
  sanitaetsdienst,
  schluessel,
  sonntagKTW,
  stundennachweis,
  veranstaltung,
  wochenKTW
});
