import { GET_NEWSLETTERS, NEWSLETTER_ERROR, GET_NEWSLETTER, NEWSLETTER_DELETED } from '../actions/types';

const initialState = {
  newsletters: [],
  newsletter: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_NEWSLETTERS:
      return {
        ...state,
        newsletters: payload,
        loading: false
      };
    case GET_NEWSLETTER:
      return {
        ...state,
        newsletter: payload,
        loading: false
      };
    case NEWSLETTER_ERROR:
      return {
        ...state,
        loading: false
      };
    case NEWSLETTER_DELETED:
    default:
      return state;
  }
}
