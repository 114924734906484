import { GET_DIENSTABEND, GET_DIENSTABENDE, DIENSTABEND_ERROR } from '../actions/types';

const initialState = {
  dienstabende: [],
  dienstabend: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DIENSTABEND:
      return {
        ...state,
        dienstabend: payload,
        loading: false
      };
    case GET_DIENSTABENDE:
      return {
        ...state,
        dienstabende: payload,
        loading: false
      };
    case DIENSTABEND_ERROR:
      return {
        ...state,
        dienstabende: [],
        dienstabend: null,
        loading: false
      };
    default:
      return state;
  }
}
