import React, { Fragment, useState } from 'react';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ScrollPanel } from 'primereact/scrollpanel';

const Lizenzen = () => {
  const [isPanelClosed, tooglePanel] = useState(true);

  return (
    <Accordion>
      <AccordionTab
        header={
          <Fragment>
            <i className='fas fa-key'></i> Lizenzen
          </Fragment>
        }
        toggleable={true}
        collapsed={isPanelClosed}
        onToggle={(e) => tooglePanel(e.value)}
      >
        <ScrollPanel style={{ height: '200px' }}>
          <a href='https://github.com/axios/axios/blob/master/LICENSE' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='Axios' />
          </a>
          <br />
          <a
            href='https://github.com/kelektiv/node.bcrypt.js/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Bcrypt' />
          </a>
          <br />
          <a href='https://github.com/jgthms/bulma/blob/master/LICENSE' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='Bulma' />
          </a>
          <br />
          <a
            href='https://github.com/chartjs/Chart.js/blob/master/LICENSE.md'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Chart.js' />
          </a>
          <br />
          <a
            href='https://github.com/JedWatson/classnames/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='ClassNames' />
          </a>
          <br />
          <a
            href='https://github.com/kimmobrunfeldt/concurrently/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Concurrently' />
          </a>
          <br />
          <a
            href='https://github.com/lorenwest/node-config/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Config' />
          </a>
          <br />
          <a
            href='https://github.com/date-fns/date-fns/blob/master/LICENSE.md'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='date-fns' />
          </a>
          <br />
          <a href='https://www.docker.com/legal/components-licenses' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='Docker' />
          </a>
          <br />
          <a href='https://github.com/expressjs/express/blob/master/LICENSE' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='Express' />
          </a>
          <br />
          <a
            href='https://github.com/express-validator/express-validator/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Express-Validator' />
          </a>
          <br />
          <a href='https://fontawesome.com/license/free' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='Font Awesome' />
          </a>
          <br />
          <a
            href='https://github.com/fullcalendar/fullcalendar/blob/master/LICENSE.txt'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='FullCalendar' />
          </a>
          <br />
          <a
            href='https://github.com/auth0/node-jsonwebtoken/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='JsonWebToken' />
          </a>
          <br />
          <a
            href='https://github.com/matrix-org/matrix-js-sdk/blob/v9.7.0/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Matrix' />
          </a>
          <br />
          <a
            href='https://github.com/moment/momentjs.com/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Moment' />
          </a>
          <br />
          <a href='https://www.mongodb.com/community/licensing' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='MongoDB' />
          </a>
          <br />
          <a
            href='https://github.com/Automattic/mongoose/blob/master/LICENSE.md'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Mongoose' />
          </a>
          <br />
          <a
            href='https://github.com/node-cron/node-cron/blob/master/LICENSE.md'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Node-Cron' />
          </a>
          <br />
          <a href='https://github.com/nodejs/node/blob/master/LICENSE' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='NodeJS' />
          </a>
          <br />
          <a
            href='https://github.com/nodemailer/nodemailer/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Nodemailer' />
          </a>
          <br />
          <a href='https://github.com/remy/nodemon/blob/master/LICENSE' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='Nodemon' />
          </a>
          <br />
          <a href='https://www.npmjs.com/policies/npm-license' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='NPM' />
          </a>
          <br />
          <a href='https://github.com/bpampuch/pdfmake/blob/master/LICENSE' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='pdfmake' />
          </a>
          <br />
          <a
            href='https://github.com/primefaces/primeflex/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='PrimeFlex' />
          </a>
          <br />
          <a
            href='https://github.com/primefaces/primeicons/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='PrimeIcons' />
          </a>
          <br />
          <a
            href='https://github.com/primefaces/primereact/blob/master/LICENSE.md'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='PrimeReact' />
          </a>
          <br />
          <a
            href='https://github.com/sindresorhus/query-string/blob/master/license'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='query-string' />
          </a>
          <br />
          <a href='https://github.com/quilljs/quill/blob/develop/LICENSE' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='Quill' />
          </a>
          <br />
          <a href='https://github.com/facebook/react/blob/master/LICENSE' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='React' />
          </a>
          <br />
          <a
            href='https://github.com/Hacker0x01/react-datepicker/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='React-Datepicker' />
          </a>
          <br />
          <a
            href='https://github.com/motorcycle/react-dom/blob/master/LICENSE.md'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='React-DOM' />
          </a>
          <br />
          <a
            href='https://github.com/headzoo/react-moment/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='React-Moment' />
          </a>
          <br />
          <a
            href='https://github.com/reduxjs/react-redux/blob/master/LICENSE.md'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='React-Redux' />
          </a>
          <br />
          <a
            href='https://github.com/ReactTraining/react-router/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='React-Router-DOM' />
          </a>
          <br />
          <a
            href='https://github.com/facebook/create-react-app/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='React-Scripts' />
          </a>
          <br />
          <a
            href='https://github.com/reactjs/react-transition-group/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='React-Transition-Group' />
          </a>
          <br />
          <a href='https://github.com/reduxjs/redux/blob/master/LICENSE.md' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='Redux' />
          </a>
          <br />
          <a
            href='https://github.com/zalmoxisus/redux-devtools-extension/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Redux-Devtool-Extension' />
          </a>
          <br />
          <a
            href='https://github.com/reduxjs/redux-thunk/blob/master/LICENSE.md'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='Redux-Thunk' />
          </a>
          <br />
          <a href='https://github.com/request/request/blob/master/LICENSE' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='Request' />
          </a>
          <br />
          <a href='https://github.com/uuidjs/uuid/blob/master/LICENSE.md' target='_blank' rel='noopener noreferrer'>
            <Button className='p-button-secondary is-full-width' label='UUID' />
          </a>
          <br />
          <a
            href='https://github.com/perry-mitchell/webdav-client/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='WebDAV' />
          </a>
          <br />
          <a
            href='https://github.com/BytemarkHosting/docker-webdav/blob/master/LICENSE'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Button className='p-button-secondary is-full-width' label='WebDAV Docker' />
          </a>
        </ScrollPanel>
      </AccordionTab>
    </Accordion>
  );
};

export default Lizenzen;
