import { GET_AUSBILDUNGEN, AUSBILDUNG_ERROR, GET_AUSBILDUNG, AUSBILDUNG_DELETED } from '../actions/types';

const initialState = {
  ausbildungen: [],
  ausbildung: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_AUSBILDUNGEN:
      return {
        ...state,
        ausbildungen: payload,
        loading: false
      };
    case GET_AUSBILDUNG:
      return {
        ...state,
        ausbildung: payload,
        loading: false
      };
    case AUSBILDUNG_ERROR:
      return {
        ...state,
        loading: false
      };
    case AUSBILDUNG_DELETED:
    default:
      return state;
  }
}
