import { GET_LEHRGAENGE, LEHRGANG_ERROR, GET_LEHRGANG } from '../actions/types';

const initialState = {
  lehrgang: null,
  lehrgaenge: [],
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LEHRGANG:
      return {
        ...state,
        lehrgang: payload,
        loading: false
      };
    case GET_LEHRGAENGE:
      return {
        ...state,
        lehrgaenge: payload,
        loading: false
      };
    case LEHRGANG_ERROR:
      return {
        ...state,
        lehrgang: null,
        lehrgaenge: [],
        loading: false
      };
    default:
      return state;
  }
}
