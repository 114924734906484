import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Spinner from '../layout/Spinner';
// import NotFound from '../layout/NoutFound'; TODO: Problem beim rendern mit multiplen Switches

// Importieren der einzelnen Componenten
const Abrechnung = lazy(() => import('../abrechnung/Abrechnung'));
const AddAusbildung = lazy(() => import('../ausbildungen/AddAusbildung'));
const AddBlutspende = lazy(() => import('../blutspende/AddBlutspende'));
const AddChangelog = lazy(() => import('../changelog/AddChangelog'));
const AddDienstabend = lazy(() => import('../dienstabende/AddDienstabend'));
const AddEinsatzbericht = lazy(() => import('../einsatzberichte/AddEinsatzbericht'));
const AddEinweisung = lazy(() => import('../admin/fahrzeugeinweisung/AddEinweisung'));
const AddFahrzeug = lazy(() => import('../admin/fahrzeug/AddFahrzeug'));
const AddFahrzeugbelegung = lazy(() => import('../fahrzeugbelegung/AddFahrzeugbelegung'));
const AddKleidung = lazy(() => import('../kleiderkammer/AddKleidung'));
const AddLehrgang = lazy(() => import('../admin/lehrgang/AddLehrgang'));
const AddMelder = lazy(() => import('../admin/melder/AddMelder'));
const AddNewsletter = lazy(() => import('../newsletter/AddNewsletter'));
const AddOrt = lazy(() => import('../admin/ort/AddOrt'));
const AddRaumbelegung = lazy(() => import('../raumbelegung/AddRaumbelegung'));
const AddRettungsdienstfortbildung = lazy(() =>
  import('../admin/rettungsdienstfortbildung/AddRettungsdienstfortbildung')
);
const AddSanitaetsdienst = lazy(() => import('../sanitaetsdienste/AddSanitaetsdienst'));
const AddSchluessel = lazy(() => import('../admin/schluessel/AddSchluessel'));
const AddSonntagKTW = lazy(() => import('../ktw/sonntag/AddSonntagKTW'));
const AddStundennachweis = lazy(() => import('../profile/stundennachweis/AddStundennachweis'));
const AddStundennachweise = lazy(() => import('../admin/stundennachweis/AddStundennachweise'));
const AddVeranstaltung = lazy(() => import('../veranstaltungen/AddVeranstaltung'));
const AddWochenKTW = lazy(() => import('../ktw/woche/AddWochenKTW'));
const Alert = lazy(() => import('../layout/Alert'));
const AlleRechte = lazy(() => import('../admin/rechte/AlleRechte'));
const AlleStundennachweise = lazy(() => import('../admin/stundennachweis/AlleStundennachweise'));
const Ausbildung = lazy(() => import('../ausbildungen/Ausbildung'));
const Ausbildungen = lazy(() => import('../ausbildungen/Ausbildungen'));
const Auswertung = lazy(() => import('../admin/stundennachweis/Auswertung'));
const Blutspende = lazy(() => import('../blutspende/Blutspende'));
const Blutspenden = lazy(() => import('../blutspende/Blutspenden'));
const Bug = lazy(() => import('../feedback/Bug'));
const Changelog = lazy(() => import('../changelog/Changelog'));
const ChangePasswort = lazy(() => import('../profile/ChangePasswort'));
const Dashboard = lazy(() => import('../dashboard/Dashboard'));
const Dienstabend = lazy(() => import('../dienstabende/Dienstabend'));
const Dienstabende = lazy(() => import('../dienstabende/Dienstabende'));
const EditAusbildung = lazy(() => import('../ausbildungen/EditAusbildung'));
const EditBlutspende = lazy(() => import('../blutspende/EditBlutspende'));
const EditChangelog = lazy(() => import('../changelog/EditChangelog'));
const EditDienstabend = lazy(() => import('../dienstabende/EditDienstabend'));
const EditEinsatzbericht = lazy(() => import('../einsatzberichte/EditEinsatzbericht'));
const EditEinweisung = lazy(() => import('../admin/fahrzeugeinweisung/EditEinweisung'));
const EditFahrzeug = lazy(() => import('../admin/fahrzeug/EditFahrzeug'));
const EditFahrzeugbelegungen = lazy(() => import('../fahrzeugbelegung/EditFahrzeugbelegung'));
const EditKleidung = lazy(() => import('../kleiderkammer/EditKleidung'));
const EditLehrgang = lazy(() => import('../admin/lehrgang/EditLehrgang'));
const EditMelder = lazy(() => import('../admin/melder/EditMelder'));
const EditNewsletter = lazy(() => import('../newsletter/EditNewsletter'));
const EditOrt = lazy(() => import('../admin/ort/EditOrt'));
const EditProfile = lazy(() => import('../profile/EditProfile'));
const EditRaumbelegung = lazy(() => import('../raumbelegung/EditRaumbelegung'));
const EditRettungsdienstfortbildung = lazy(() =>
  import('../admin/rettungsdienstfortbildung/EditRettungsdienstfortbildung')
);
const EditSanitaetsdienst = lazy(() => import('../sanitaetsdienste/EditSanitaetsdienst'));
const EditSchluessel = lazy(() => import('../admin/schluessel/EditSchluessel'));
const EditSonntagKTW = lazy(() => import('../ktw/sonntag/EditSonntagKTW'));
const EditStundennachweis = lazy(() => import('../profile/stundennachweis/EditStundennachweis'));
const EditStundennachweise = lazy(() => import('../admin/stundennachweis/EditStundennachweise'));
const EditVeranstaltung = lazy(() => import('../veranstaltungen/EditVeranstaltung'));
const EditWochenKTW = lazy(() => import('../ktw/woche/EditWochenKTW'));
const Einsatzbericht = lazy(() => import('../einsatzberichte/Einsatzbericht'));
const Einsatzberichte = lazy(() => import('../einsatzberichte/Einsatzberichte'));
const Fahrzeugbelegungen = lazy(() => import('../fahrzeugbelegung/Fahrzeugbelegungen'));
const Fahrzeuge = lazy(() => import('../admin/fahrzeug/Fahrzeuge'));
const Fahrzeugeinweisungen = lazy(() => import('../admin/fahrzeugeinweisung/Fahrzeugeinweisungen'));
const HelferEinkleiden = lazy(() => import('../kleiderkammer/HelferEinkleiden'));
const HistorySanitaetsdienst = lazy(() => import('../sanitaetsdienste/HistorySanitaetsdienst'));
const HistorySonntagKTW = lazy(() => import('../ktw/sonntag/HistorySonntagKTW'));
const HistoryWochenKTW = lazy(() => import('../ktw/woche/HistoryWochenKTW'));
const Kalender = lazy(() => import('../kalender/Kalender'));
const Kleiderkammer = lazy(() => import('../kleiderkammer/Kleiderkammer'));
const Kummerkasten = lazy(() => import('../feedback/Kummerkasten'));
const Lehrgaenge = lazy(() => import('../admin/lehrgang/Lehrgaenge'));
const Login = lazy(() => import('../auth/Login'));
const Melder = lazy(() => import('../admin/melder/Melder'));
const MyProfile = lazy(() => import('../profile/MyProfile'));
const Newsletter = lazy(() => import('../newsletter/Newsletter'));
const Organigramm = lazy(() => import('../organigramm/Organigramm'));
const OrganigrammAblauforganisation = lazy(() => import('../organigramm/OrganigrammAblauforganisation'));
const OrganigrammFahrzeuge = lazy(() => import('../organigramm/OrganigrammFahrzeuge'));
const OrganigrammMaterial = lazy(() => import('../organigramm/OrganigrammMaterial'));
const OrganigrammPersonal = lazy(() => import('../organigramm/OrganigrammPersonal'));
const OrganigrammPersonalAusbildung = lazy(() => import('../organigramm/OrganigrammPersonalAusbildung'));
const OrganigrammPersonalBetreuung = lazy(() => import('../organigramm/OrganigrammPersonalBetreuung'));
const OrganigrammPresse = lazy(() => import('../organigramm/OrganigrammPresse'));
const Orte = lazy(() => import('../admin/ort/Orte'));
const PasswortVergessen = lazy(() => import('../profile/PasswortVergessen'));
const Profile = lazy(() => import('../profile/Profile'));
const Profiles = lazy(() => import('../profile/Profiles'));
const Raumbelegung = lazy(() => import('../raumbelegung/Raumbelegung'));
const Raumbelegungen = lazy(() => import('../raumbelegung/Raumbelegungen'));
const Register = lazy(() => import('../auth/Register'));
const ResetPasswort = lazy(() => import('../profile/ResetPasswort'));
const Rettungsdienstfortbildungen = lazy(() =>
  import('../admin/rettungsdienstfortbildung/Rettungsdienstfortbildungen')
);
const Sanitaetsdienst = lazy(() => import('../sanitaetsdienste/Sanitaetsdienst'));
const Sanitaetsdienste = lazy(() => import('../sanitaetsdienste/Sanitaetsdienste'));
const Schluessel = lazy(() => import('../admin/schluessel/Schluessel'));
const SonntagKTW = lazy(() => import('../ktw/sonntag/SonntagKTW'));
const Stundennachweise = lazy(() => import('../profile/stundennachweis/Stundennachweise'));
const Veranstaltung = lazy(() => import('../veranstaltungen/Veranstaltung'));
const Veranstaltungen = lazy(() => import('../veranstaltungen/Veranstaltungen'));
const Verbesserungsvorschlag = lazy(() => import('../feedback/Verbesserungsvorschlag'));
const WochenKTW = lazy(() => import('../ktw/woche/WochenKTW'));

/**
 * Alle Routen in der Anwendung
 *
 * @author terfort
 */

const Routes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Alert />
      <Switch>
        <Route exact path='/' component={Login} />
        <Route exact path='/passwort-vergessen' component={PasswortVergessen} />
        <Route exact path='/passwort-reset/:token' component={ResetPasswort} />
      </Switch>
      <section className='container'>
        <Switch>
          <PrivateRoute exact path='/abrechnung' component={Abrechnung} />
          <PrivateRoute exact path='/ausbildung/neu' component={AddAusbildung} />
          <PrivateRoute exact path='/blutspende/neu' component={AddBlutspende} />
          <PrivateRoute exact path='/changelog/neu' component={AddChangelog} />
          <PrivateRoute exact path='/dienstabende/neu' component={AddDienstabend} />
          <PrivateRoute exact path='/einsatzberichte/neu' component={AddEinsatzbericht} />
          <PrivateRoute exact path='/fahrzeugeinweisungen/neu' component={AddEinweisung} />
          <PrivateRoute exact path='/fahrzeuge/neu' component={AddFahrzeug} />
          <PrivateRoute exact path='/fahrzeugbelegungen/neu' component={AddFahrzeugbelegung} />
          <PrivateRoute exact path='/kleiderkammer/neu' component={AddKleidung} />
          <PrivateRoute exact path='/lehrgaenge/neu' component={AddLehrgang} />
          <PrivateRoute exact path='/melder/neu' component={AddMelder} />
          <PrivateRoute exact path='/newsletter/neu' component={AddNewsletter} />
          <PrivateRoute exact path='/orte/neu' component={AddOrt} />
          <PrivateRoute exact path='/raumbelegungen/neu' component={AddRaumbelegung} />
          <PrivateRoute exact path='/rettungsdienstfortbildungen/neu' component={AddRettungsdienstfortbildung} />
          <PrivateRoute exact path='/sanitaetsdienste/neu' component={AddSanitaetsdienst} />
          <PrivateRoute exact path='/schluessel/neu' component={AddSchluessel} />
          <PrivateRoute exact path='/ktw/sonntag/neu' component={AddSonntagKTW} />
          <PrivateRoute exact path='/profiles/myProfile/stundennachweise/neu' component={AddStundennachweis} />
          <PrivateRoute exact path='/stundennachweise/neu' component={AddStundennachweise} />
          <PrivateRoute exact path='/veranstaltungen/neu' component={AddVeranstaltung} />
          <PrivateRoute exact path='/ktw/woche/neu' component={AddWochenKTW} />
          <PrivateRoute exact path='/rechte' component={AlleRechte} />
          <PrivateRoute exact path='/stundennachweise' component={AlleStundennachweise} />
          <PrivateRoute exact path='/ausbildung/:id' component={Ausbildung} />
          <PrivateRoute exact path='/ausbildung' component={Ausbildungen} />
          <PrivateRoute exact path='/auswertung' component={Auswertung} />
          <PrivateRoute exact path='/blutspende/:id' component={Blutspende} />
          <PrivateRoute exact path='/blutspenden' component={Blutspenden} />
          <PrivateRoute exact path='/feedback/bug' component={Bug} />
          <PrivateRoute exact path='/changelog' component={Changelog} />
          <PrivateRoute exact path='/changePasswort' component={ChangePasswort} />
          <PrivateRoute exact path='/dashboard' component={Dashboard} />
          <PrivateRoute exact path='/dienstabend/:id' component={Dienstabend} />
          <PrivateRoute exact path='/dienstabende' component={Dienstabende} />
          <PrivateRoute exact path='/ausbildung/edit/:id' component={EditAusbildung} />
          <PrivateRoute exact path='/changelog/edit/:id' component={EditChangelog} />
          <PrivateRoute exact path='/blutspende/edit/:id' component={EditBlutspende} />
          <PrivateRoute exact path='/dienstabend/edit/:id' component={EditDienstabend} />
          <PrivateRoute exact path='/einsatzbericht/edit/:id' component={EditEinsatzbericht} />
          <PrivateRoute exact path='/fahrzeugeinweisung/edit/:id' component={EditEinweisung} />
          <PrivateRoute exact path='/fahrzeuge/edit/:id' component={EditFahrzeug} />
          <PrivateRoute exact path='/fahrzeugbelegung/edit/:id' component={EditFahrzeugbelegungen} />
          <PrivateRoute exact path='/kleiderkammer/edit/:id' component={EditKleidung} />
          <PrivateRoute exact path='/lehrgaenge/edit/:id' component={EditLehrgang} />
          <PrivateRoute exact path='/melder/edit/:id' component={EditMelder} />
          <PrivateRoute exact path='/newsletter/edit/:id' component={EditNewsletter} />
          <PrivateRoute exact path='/orte/edit/:id' component={EditOrt} />
          <PrivateRoute exact path='/profiles/edit/:id' component={EditProfile} />
          <PrivateRoute exact path='/raumbelegung/edit/:id' component={EditRaumbelegung} />
          <PrivateRoute exact path='/rettungsdienstfortbildung/edit/:id' component={EditRettungsdienstfortbildung} />
          <PrivateRoute exact path='/sanitaetsdienst/:id/edit' component={EditSanitaetsdienst} />
          <PrivateRoute exact path='/schluessel/edit/:id' component={EditSchluessel} />
          <PrivateRoute exact path='/ktw/sonntag/edit/:id' component={EditSonntagKTW} />
          <PrivateRoute exact path='/profiles/myProfile/stundennachweise/edit/:id' component={EditStundennachweis} />
          <PrivateRoute exact path='/stundennachweise/edit/:id' component={EditStundennachweise} />
          <PrivateRoute exact path='/veranstaltung/:id/edit' component={EditVeranstaltung} />
          <PrivateRoute exact path='/ktw/woche/edit/:id' component={EditWochenKTW} />
          <PrivateRoute exact path='/einsatzbericht/:id' component={Einsatzbericht} />
          <PrivateRoute exact path='/einsatzberichte' component={Einsatzberichte} />
          <PrivateRoute exact path='/fahrzeugbelegungen' component={Fahrzeugbelegungen} />
          <PrivateRoute exact path='/fahrzeuge' component={Fahrzeuge} />
          <PrivateRoute exact path='/fahrzeugeinweisungen' component={Fahrzeugeinweisungen} />
          <PrivateRoute exact path='/kleiderkammer/einkleiden' component={HelferEinkleiden} />
          <PrivateRoute exact path='/sanitaetsdienst/history/:id' component={HistorySanitaetsdienst} />
          <PrivateRoute exact path='/ktw/sonntag/history/:id' component={HistorySonntagKTW} />
          <PrivateRoute exact path='/ktw/woche/history/:id' component={HistoryWochenKTW} />
          <PrivateRoute exact path='/kalender' component={Kalender} />
          <PrivateRoute exact path='/kleiderkammer' component={Kleiderkammer} />
          <PrivateRoute exact path='/kummerkasten' component={Kummerkasten} />
          <PrivateRoute exact path='/lehrgaenge' component={Lehrgaenge} />
          <PrivateRoute exact path='/melder' component={Melder} />
          <PrivateRoute exact path='/profiles/myProfile' component={MyProfile} />
          <PrivateRoute exact path='/newsletter' component={Newsletter} />
          <PrivateRoute exact path='/organigramm' component={Organigramm} />
          <PrivateRoute exact path='/organigramm/ablauforganisation' component={OrganigrammAblauforganisation} />
          <PrivateRoute exact path='/organigramm/fahrzeuge' component={OrganigrammFahrzeuge} />
          <PrivateRoute exact path='/organigramm/material' component={OrganigrammMaterial} />
          <PrivateRoute exact path='/organigramm/personal' component={OrganigrammPersonal} />
          <PrivateRoute exact path='/organigramm/personal/ausbildung' component={OrganigrammPersonalAusbildung} />
          <PrivateRoute exact path='/organigramm/personal/betreuung' component={OrganigrammPersonalBetreuung} />
          <PrivateRoute exact path='/organigramm/presse' component={OrganigrammPresse} />
          <PrivateRoute exact path='/orte' component={Orte} />
          <PrivateRoute exact path='/profile/:id' component={Profile} />
          <PrivateRoute exact path='/kontakte' component={Profiles} />
          <PrivateRoute exact path='/raumbelegung/:id' component={Raumbelegung} />
          <PrivateRoute exact path='/raumbelegungen' component={Raumbelegungen} />
          <PrivateRoute exact path='/users/register' component={Register} />
          <PrivateRoute exact path='/rettungsdienstfortbildungen' component={Rettungsdienstfortbildungen} />
          <PrivateRoute exact path='/sanitaetsdienst/:id' component={Sanitaetsdienst} />
          <PrivateRoute exact path='/sanitaetsdienste' component={Sanitaetsdienste} />
          <PrivateRoute exact path='/schluessel' component={Schluessel} />
          <PrivateRoute exact path='/ktw/sonntag' component={SonntagKTW} />
          <PrivateRoute exact path='/profiles/myProfile/stundennachweise' component={Stundennachweise} />
          <PrivateRoute exact path='/veranstaltung/:id' component={Veranstaltung} />
          <PrivateRoute exact path='/veranstaltungen' component={Veranstaltungen} />
          <PrivateRoute exact path='/feedback/verbesserungsvorschlag' component={Verbesserungsvorschlag} />
          <PrivateRoute exact path='/ktw/woche' component={WochenKTW} />
          {/**<Route component={NotFound} />*/}
        </Switch>
      </section>
    </Suspense>
  );
};

export default Routes;
