import { GET_VERANSTALTUNG, GET_VERANSTALTUNGEN, VERANSTALTUNG_ERROR } from '../actions/types';

const initialState = {
  veranstaltungen: [],
  veranstaltung: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_VERANSTALTUNG:
      return {
        ...state,
        veranstaltung: payload,
        loading: false
      };
    case GET_VERANSTALTUNGEN:
      return {
        ...state,
        veranstaltungen: payload,
        loading: false
      };
    case VERANSTALTUNG_ERROR:
      return {
        ...state,
        raumbelegungen: [],
        raumbelegung: null,
        loading: false
      };
    default:
      return state;
  }
}
