import axios from 'axios';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  PASSWORT_CHANGED,
  USER_ERROR,
  USER_UPDATE
} from './types';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import { createProfile, updateProfile } from './profile';

export const register = (formData, history) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    if (formData.email.length >= 1 && formData.primary_email === '') {
      formData.primary_email = formData.email[0];
    }

    if (formData.telefon.length >= 1 && formData.primary_telefon === '') {
      formData.primary_telefon = formData.telefon[0];
    }

    const res = await axios.post('/api/users', formData, config);

    dispatch({
      type: REGISTER_SUCCESS
    });

    formData.userId = res.data._id;

    dispatch(createProfile(formData, history));
    dispatch(setAlert('Benutzer erfolgreich registriert', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get('/api/auth');

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

export const login = (username, passwort) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ username, passwort });

  try {
    const res = await axios.post('/api/auth', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};

export const changePasswort = (formData, history) => async (dispatch) => {
  try {
    if (formData.neuesPasswort !== formData.neuesPasswort2) {
      dispatch(setAlert('Passwörter stimmen nicht überein', 'error'));
    } else {
      await axios.post('/api/users/changePassword', formData);

      dispatch({
        type: PASSWORT_CHANGED
      });

      dispatch(setAlert('Passwort erfolgreich geändert', 'success'));
      history.push('/dashboard');
    }
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: USER_ERROR
    });
  }
};

export const resetPasswort = (userId) => async (dispatch) => {
  try {
    await axios.post(`api/users/resetPassword/${userId}`);

    dispatch({
      type: PASSWORT_CHANGED
    });
    dispatch(setAlert('Passwort zurückgesetzt', 'success'));
  } catch (error) {
    dispatch({
      type: USER_ERROR
    });
    dispatch(setAlert('Passwort zurücksetzen fehlgeschlagen', 'error'));
  }
};

export const updateUser = (formData, history) => async (dispatch) => {
  try {
    if (formData.email.length === 1 && formData.primary_email === '') {
      formData.primary_email = formData.email[0];
    }

    const res = await axios.put('/api/users', formData);

    dispatch({
      type: USER_UPDATE
    });

    formData.userId = res.data._id;

    dispatch(updateProfile(formData, history));
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: USER_ERROR
    });
  }
};

export const passwortVergessen = (email, history) => async (dispatch) => {
  try {
    await axios.post('/api/users/passwortVergessen', { email });
    history.push('/');
    dispatch(setAlert('E-Mail zum Zurücksetzen des Passworts verschickt', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
  }
};

export const passwortReset = (formData, token, history) => async (dispatch) => {
  try {
    await axios.post('/api/users/resetPasswort', formData, { headers: { 'x-auth-token': token } });
    history.push('/');
    dispatch(setAlert('Passwort geändert', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
  }
};
