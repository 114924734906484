/**
 * Rechte für die Anwendung welche Usern zugewiesen werden können.
 *
 * @author terfort
 */

// Sanitätsdienste
export const CREATE_SANDIENST = 'CREATE_SANDIENST';
export const READ_SANDIENST = 'READ_SANDIENST';
export const UPDATE_SANDIENST = 'UPDATE_SANDIENST';
export const DELETE_SANDIENST = 'DELETE_SANDIENST';
export const READ_SANITAETSDIENST_HISTORY = 'READ_SANITAETSDIENST_HISTORY';
export const CREATE_EXTERN_SANDIENST = 'CREATE_EXTERN_SANDIENST';
export const DELETE_EXTERN_SANDIENST = 'DELETE_EXTERN_SANDIENST';

// KTW allgemein
export const UPDATE_KTW_DATUM = 'UPDATE_KTW_DATUM';

// Wochen KTW
export const CREATE_KTW_WOCHE = 'CREATE_KTW_WOCHE';
export const READ_KTW_WOCHE = 'READ_KTW_WOCHE';
export const UPDATE_KTW_WOCHE = 'UPDATE_KTW_WOCHE';
export const DELETE_KTW_WOCHE = 'DELETE_KTW_WOCHE';
export const READ_WOCHEN_KTW_HISTORY = 'READ_WOCHEN_KTW_HISTORY';
export const SET_KTW_KENNUNG = 'SET_KTW_KENNUNG';

// Sonn- und Feiertags KTW
export const CREATE_SONNTAG_KTW = 'CREATE_SONNTAG_KTW';
export const READ_SONNTAG_KTW = 'READ_SONNTAG_KTW';
export const UPDATE_SONNTAG_KTW = 'UPDATE_SONNTAG_KTW';
export const DELETE_SONNTAG_KTW = 'DELETE_SONNTAG_KTW';
export const READ_SONNTAG_KTW_HISTORY = 'READ_SONNTAG_KTW_HISTORY';
export const SONNTAG_KTW_ABSAGEN = 'SONNTAG_KTW_ABSAGEN';

// Fahrzeugbelegungen
export const CREATE_FAHRZEUGBELEGUNG = 'CREATE_FAHRZEUGBELEGUNG';
export const READ_FAHRZEUGBELEGUNG = 'READ_FAHRZEUGBELEGUNG';
export const UPDATE_FAHRZEUGBELEGUNG = 'UPDATE_FAHRZEUGBELEGUNG';
export const DELETE_FAHRZEUGBELEGUNG = 'DELETE_FAHRZEUGBELEGUNG';

// Raumbelegungen
export const CREATE_RAUMBELEGUNG = 'CREATE_RAUMBELEGUNG';
export const READ_RAUMBELEGUNG = 'READ_RAUMBELEGUNG';
export const UPDATE_RAUMBELEGUNG = 'UPDATE_RAUMBELEGUNG';
export const DELETE_RAUMBELEGUNG = 'DELETE_RAUMBELEGUNG';

// Ausbildungen
export const CREATE_AUSBILDUNG = 'CREATE_AUSBILDUNG';
export const READ_AUSBILDUNG = 'READ_AUSBILDUNG';
export const UPDATE_AUSBILDUNG = 'UPDATE_AUSBILDUNG';
export const DELETE_AUSBILDUNG = 'DELETE_AUSBILDUNG';

// Dienstabende
export const CREATE_DIENSTABEND = 'CREATE_DIENSTABEND';
export const READ_DIENSTABEND = 'READ_DIENSTABEND';
export const UPDATE_DIENSTABEND = 'UPDATE_DIENSTABEND';
export const DELETE_DIENSTABEND = 'DELETE_DIENSTABEND';

// Veranstaltungen
export const CREATE_VERANSTALTUNG = 'CREATE_VERANSTALTUNG';
export const READ_VERANSTALTUNG = 'READ_VERANSTALTUNG';
export const UPDATE_VERANSTALTUNG = 'UPDATE_VERANSTALTUNG';
export const UPDATE_VERANSTALTUNG_ANMELDESCHLUSS = 'UPDATE_VERANSTALTUNG_ANMELDESCHLUSS';
export const UPDATE_VERANSTALTUNG_OVER_ANMELDESCHLUSS_USER = 'UPDATE_VERANSTALTUNG_OVER_ANMELDESCHLUSS_USER';
export const DELETE_VERANSTALTUNG = 'DELETE_VERANSTALTUNG';

// User
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER_AUSBILDUNG = 'UPDATE_USER_AUSBILDUNG';
export const DELETE_USER_AUSBILDUNG = 'DELETE_USER_AUSBILDUNG';
export const READ_USER_RECHTE = 'READ_USER_RECHTE';
export const UPDATE_USER_RECHTE = 'UPDATE_USER_RECHTE';
export const RESET_PASSWORT = 'RESET_PASSWORT';
export const READ_LAST_LOGIN = 'READ_LAST_LOGIN';
export const READ_USER_PROPERTIES = 'READ_USER_PROPERTIES';
export const UPDATE_USER_PROPERTIES = 'UPDATE_USER_PROPERTIES';
export const READ_USER_SICHTBARKEIT = 'READ_USER_SICHTBARKEIT';
export const UPDATE_USER_SICHTBARKEIT = 'UPDATE_USER_SICHTBARKEIT';
export const READ_UNSICHTBARE_USER = 'READ_UNSICHTBARE_USER';
export const READ_GEBURTSDATUM = 'READ_GEBURTSDATUM';
export const UPDATE_GEBURTSDATUM = 'UPDATE_GEBURTSDATUM';
export const READ_USER_EMAIL_BENACHRICHTIGUNG = 'READ_USER_EMAIL_BENACHRICHTIGUNG';
export const SET_EMAIL_BENACHRICHTIGUNG = 'SET_EMAIL_BENACHRICHTIGUNG';
export const SET_KTW_EMAIL_BENACHRICHTIGUNG = 'SET_KTW_EMAIL_BENACHRICHTIGUNG';
export const SEE_VERTEILER = 'SEE_VERTEILER';
export const SET_VERTEILER = 'SET_VERTEILER';
export const SET_GROUPALARM = 'SET_GROUPALARM';
export const SET_FUNKTIONEN = 'SET_FUNKTIONEN';
export const SET_GRUPPE = 'SET_GRUPPE';

// Fahrzeuge
export const CREATE_FAHRZEUG = 'CREATE_FAHRZEUG';
export const READ_FAHRZEUG = 'READ_FAHRZEUG';
export const UPDATE_FAHRZEUG = 'UPDATE_FAHRZEUG';
export const DELETE_FAHRZEUG = 'DELETE_FAHRZEUG';

// Lehrgang
export const CREATE_ADMIN_LEHRGANG = 'CREATE_ADMIN_LEHRGANG';
export const READ_ADMIN_LEHRGANG = 'READ_ADMIN_LEHRGANG';
export const UPDATE_ADMIN_LEHRGANG = 'UPDATE_ADMIN_LEHRGANG';
export const DELETE_ADMIN_LEHRGANG = 'DELETE_ADMIN_LEHRGANG';

// Ort
export const CREATE_ORT = 'CREATE_ORT';
export const READ_ORT = 'READ_ORT';
export const UPDATE_ORT = 'UPDATE_ORT';
export const DELETE_ORT = 'DELETE_ORT';

// Changelog
export const CREATE_CHANGELOG = 'CREATE_CHANGELOG';
export const UPDATE_CHANGELOG = 'UPDATE_CHANGELOG';
export const DELETE_CHANGELOG = 'DELETE_CHANGELOG';

// Abrechnung
export const ABRECHNUNG = 'ABRECHNUNG';

// Kalender
export const READ_C_DIENST_KALENDER = 'READ_C_DIENST_KALENDER';

// Einsatzberichte
export const CREATE_EINSATZBERICHT = 'CREATE_EINSATZBERICHT';
export const READ_EINSATZBERICHT = 'READ_EINSATZBERICHT';
export const UPDATE_EINSATZBERICHT = 'UPDATE_EINSATZBERICHT';
export const DELETE_EINSATZBERICHT = 'DELETE_EINSATZBERICHT';

// Blutspende
export const CREATE_BLUTSPENDE = 'CREATE_BLUTSPENDE';
export const READ_BLUTSPENDE = 'READ_BLUTSPENDE';
export const UPDATE_BLUTSPENDE = 'UPDATE_BLUTSPENDE';
export const DELETE_BLUTSPENDE = 'DELETE_BLUTSPENDE';

// Melder
export const CREATE_MELDER = 'CREATE_MELDER';
export const READ_MELDER = 'READ_MELDER';
export const UPDATE_MELDER = 'UPDATE_MELDER';
export const DELETE_MELDER = 'DELETE_MELDER';

// Fahrzeugeinweisungen
export const READ_FAHRZEUGEINWEISUNG = 'READ_FAHRZEUGEINWEISUNG';
export const CREATE_FAHRZEUGEINWEISUNG = 'CREATE_FAHRZEUGEINWEISUNG';
export const UPDATE_FAHRZEUGEINWEISUNG = 'UPDATE_FAHRZEUGEINWEISUNG';
export const DELETE_FAHRZEUGEINWEISUNG = 'DELETE_FAHRZEUGEINWEISUNG';

// Schlüssel
export const CREATE_SCHLUESSEL = 'CREATE_SCHLUESSEL';
export const READ_SCHLUESSEL = 'READ_SCHLUESSEL';
export const UPDATE_SCHLUESSEL = 'UPDATE_SCHLUESSEL';
export const DELETE_SCHLUESSEL = 'DELETE_SCHLUESSEL';

// Stundenauswertung
export const READ_STUNDEN_AUSWERTUNG = 'READ_STUNDEN_AUSWERTUNG';

// Stundennachweise
export const CREATE_STUNDENNACHWEISE = 'CREATE_STUNDENNACHWEISE';
export const READ_STUNDENNACHWEISE = 'READ_STUNDENNACHWEISE';
export const UPDATE_STUNDENNACHWEISE = 'UPDATE_STUNDENNACHWEISE';
export const DELETE_STUNDENNACHWEISE = 'DELETE_STUNDENNACHWEISE';

// Kleiderkammer
export const CREATE_KLEIDUNG = 'CREATE_KLEIDUNG';
export const READ_KLEIDUNG = 'READ_KLEIDUNG';
export const UPDATE_KLEIDUNG = 'UPDATE_KLEIDUNG';
export const DELETE_KLEIDUNG = 'DELETE_KLEIDUNG';

// Rettungsdienstfortbildungen
export const CREATE_RDF = 'CREATE_RDF';
export const READ_RDF = 'READ_RDF';
export const UPDATE_RDF = 'UPDATE_RDF';
export const DELETE_RDF = 'DELETE_RDF';

// Newsletter
export const CREATE_NEWSLETTER = 'CREATE_NEWSLETTER';
export const READ_NEWSLETTER = 'READ_NEWSLETTER';
export const UPDATE_NEWSLETTER = 'UPDATE_NEWSLETTER';
export const DELETE_NEWSLETTER = 'DELETE_NEWSLETTER';
