import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lizenzen from './Lizenzen';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { getNodeVersion } from '../../actions/config';
import { ScrollPanel } from 'primereact/scrollpanel';

const Info = ({ getNodeVersion, config: { nodeVersion }, toggleDialog }) => {
  useEffect(() => {
    getNodeVersion();
  }, [getNodeVersion]);

  const packageJson = require('../../../package.json');

  return (
    <ScrollPanel style={{ height: '40vh' }}>
      <p className='is-monospace is-center'>Copyright &copy; 2019-{new Date().getFullYear()} Kevin Terfort</p>
      <hr />
      <table className='table'>
        <thead>
          <tr>
            <th colSpan={2}>Versionen</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Intranet</td>
            <td className='is-monospace'>4.4</td>
            <td>
              <Link to='/changelog'>
                <Button
                  label='Changelog'
                  icon='fas fa-file-alt'
                  className='only-icon-mobile'
                  onClick={() => toggleDialog(false)}
                />
              </Link>
            </td>
          </tr>
          <tr>
            <td>Node</td>
            <td className='is-monospace'>{nodeVersion}</td>
          </tr>
          <tr>
            <td>React</td>
            <td className='is-monospace'>{packageJson.dependencies.react.replace('^', '')}</td>
          </tr>
          <tr>
            <td>Redux</td>
            <td className='is-monospace'>{packageJson.dependencies.redux.replace('^', '')}</td>
          </tr>
          <tr>
            <td>PrimeReact</td>
            <td className='is-monospace'>{packageJson.dependencies.primereact.replace('^', '')}</td>
          </tr>
          <tr>
            <td>Bulma</td>
            <td className='is-monospace'>{packageJson.dependencies.bulma.replace('^', '')}</td>
          </tr>
          <tr>
            <td>FontAwesome</td>
            <td className='is-monospace'>
              {packageJson.dependencies['@fortawesome/fontawesome-free'].replace('^', '')}
            </td>
          </tr>
        </tbody>
      </table>
      <Lizenzen />
      <hr />
      <Link to='/feedback/verbesserungsvorschlag'>
        <Button
          label='Verbesserungsvorschlag'
          icon='fas fa-code'
          className='only-icon-mobile'
          onClick={() => toggleDialog(false)}
        />
      </Link>
      &emsp;
      <Link to='/feedback/bug'>
        <Button
          label='Bug melden'
          className='p-button-danger is-pulled-right only-icon-mobile'
          icon='fas fa-bug'
          onClick={() => toggleDialog(false)}
        />
      </Link>
      <hr />
      <p className='is-center'>
        Made with <i className='fab fa-linux fa-lg'></i>, <i className='fab fa-node-js fa-lg'></i>,{' '}
        <i className='fab fa-npm fa-lg'></i>, <i className='fab fa-docker fa-lg'></i>,{' '}
        <i className='fab fa-git-alt fa-lg'></i>, <i className='fab fa-github fa-lg'></i>,{' '}
        <i className='fab fa-react fa-lg'></i>, <i className='fab fa-font-awesome fa-lg'></i>,{' '}
        <i className='fas fa-coffee fa-lg'></i> and <i className='fas fa-heart fa-lg is-red'></i>
      </p>
    </ScrollPanel>
  );
};

Info.propTypes = {
  getNodeVersion: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  config: state.config
});

export default connect(mapStateToProps, { getNodeVersion })(Info);
