import React from 'react';
import { Button } from 'primereact/button';
import alarm from '../../img/alarm.gif';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='container'>
          <div className='error'>
            <img src={alarm} alt='' />
            <h1 className='title head'>
              Ups!{' '}
              <span role='img' aria-label='Grinning Face with Sweat'>
                &#x1F605;
              </span>
            </h1>
            <h1 className='title is-1'>
              Da ist uns wohl ein Fehler unterlaufen! <br /> Sollte dieser Fehler weiterhin wiederholt auftreten, wende
              dich an deinen Systemadministrator.
            </h1>
          </div>
          <Button
            label='Fehlermeldung entfernen'
            className='p-button-secondary error-button'
            onClick={() => window.location.replace(window.location.origin + '/dashboard')}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
