import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'bulma/css/bulma.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import Header from './components/layout/Header';
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import Routes from './components/routes/Routes';
import ErrotBoundary from './components/layout/ErrorBoundary';
import setAxiosDefaults from './config/axios.config';
import { setDefaultLocale, registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import moment from 'moment';
import 'moment/locale/de';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

setAxiosDefaults();
moment.locale('de');
registerLocale('de', de);
setDefaultLocale('de');

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Header />
          <ErrotBoundary>
            <Routes />
          </ErrotBoundary>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
