import { GET_MELDERS, GET_MELDER, MELDER_DELETED, MELDER_ERROR } from '../actions/types';

const initialState = {
  melders: [],
  melder: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MELDERS:
      return {
        ...state,
        melders: payload,
        loading: false
      };
    case GET_MELDER:
      return {
        ...state,
        melder: payload,
        loading: false
      };
    case MELDER_DELETED:
      return {
        ...state,
        loading: false
      };
    case MELDER_ERROR:
    default:
      return state;
  }
}
