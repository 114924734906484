import { GET_NODE_VERSION, LETZTER_SAN_LEITER, LETZTER_BLUTSPENDE_LEITER } from '../actions/types';

const initialState = {
  nodeVersion: '',
  letzterSanLeiter: '',
  letzterBlutspendeLeiter: ''
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_NODE_VERSION:
      return {
        ...state,
        nodeVersion: payload
      };
    case LETZTER_SAN_LEITER:
      return {
        ...state,
        letzterSanLeiter: payload
      };
    case LETZTER_BLUTSPENDE_LEITER:
      return {
        ...state,
        letzterBlutspendeLeiter: payload
      };
    default:
      return state;
  }
}
