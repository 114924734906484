import { GET_CHANGELOGS, CHANGELOG_ERROR, GET_CHANGELOG, CHANGELOG_DELETED } from '../actions/types';

const initialState = {
  changelogs: [],
  changelog: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CHANGELOGS:
      return {
        ...state,
        changelogs: payload,
        loading: false
      };
    case GET_CHANGELOG:
      return {
        ...state,
        changelog: payload,
        loading: false
      };
    case CHANGELOG_ERROR:
      return {
        ...state,
        loading: false
      };
    case CHANGELOG_DELETED:
    default:
      return state;
  }
}
