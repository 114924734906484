import {
  GET_KTW_WOCHEN,
  GET_KTW_WOCHE,
  WOCHEN_KTW_ERROR,
  WOCHEN_KTW_DELETED,
  GET_14_KTW_01,
  GET_14_KTW_02
} from '../actions/types';

const initialState = {
  ktwWochen: [],
  ktwWoche: null,
  ktw1401: [],
  ktw1402: [],
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_KTW_WOCHEN:
      return {
        ...state,
        ktwWochen: payload,
        loading: false
      };
    case GET_KTW_WOCHE:
      return {
        ...state,
        ktwWoche: payload,
        loading: false
      };
    case GET_14_KTW_01:
      return {
        ...state,
        ktw1401: payload,
        loading: false
      };
    case GET_14_KTW_02:
      return {
        ...state,
        ktw1402: payload,
        loading: false
      };
    case WOCHEN_KTW_ERROR:
    case WOCHEN_KTW_DELETED:
    default:
      return {
        ...state,
        loading: false
      };
  }
}
