import React, { Fragment } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const Spinner = () => {
  return (
    <Fragment>
      <ProgressSpinner style={{ width: '200px', margin: 'auto', display: 'block' }} />
    </Fragment>
  );
};

export default Spinner;
