import { GET_FAHRZEUGBELEGUNG, GET_FAHRZEUGBELEGUNGEN, FAHRZEUGBELEGUNG_ERROR } from '../actions/types';

const initialState = {
  fahrzeugbelegungen: [],
  fahrzeugbelegung: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FAHRZEUGBELEGUNG:
      return {
        ...state,
        fahrzeugbelegung: payload,
        loading: false
      };
    case GET_FAHRZEUGBELEGUNGEN:
      return {
        ...state,
        fahrzeugbelegungen: payload,
        loading: false
      };
    case FAHRZEUGBELEGUNG_ERROR:
    default:
      return state;
  }
}
