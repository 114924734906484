import {
  GET_EINSATZBERICHTE,
  GET_EINSATZBERICHT,
  EINSATZBERICHT_DELETED,
  EINSATZBERICHT_ERROR
} from '../actions/types';

const initialState = {
  einsatzberichte: [],
  einsatzbericht: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EINSATZBERICHTE:
      return {
        ...state,
        einsatzberichte: payload,
        loading: false
      };
    case GET_EINSATZBERICHT:
      return {
        ...state,
        einsatzbericht: payload,
        loading: false
      };
    case EINSATZBERICHT_DELETED:
      return {
        ...state,
        loading: false
      };
    case EINSATZBERICHT_ERROR:
    default:
      return state;
  }
}
