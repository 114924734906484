import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout, loadUser } from '../../actions/auth';
import { SlideMenu } from 'primereact/slidemenu';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import Info from './Info';
import {
  READ_SANDIENST,
  READ_FAHRZEUGBELEGUNG,
  READ_SONNTAG_KTW,
  READ_KTW_WOCHE,
  ABRECHNUNG,
  READ_AUSBILDUNG,
  READ_RAUMBELEGUNG,
  CREATE_USER,
  READ_ORT,
  READ_ADMIN_LEHRGANG,
  READ_FAHRZEUG,
  READ_DIENSTABEND,
  READ_VERANSTALTUNG,
  READ_BLUTSPENDE,
  READ_EINSATZBERICHT,
  READ_MELDER,
  READ_USER_RECHTE,
  READ_FAHRZEUGEINWEISUNG,
  READ_SCHLUESSEL,
  READ_STUNDEN_AUSWERTUNG,
  READ_STUNDENNACHWEISE,
  READ_KLEIDUNG,
  READ_RDF
} from '../../utils/rechte';

const Header = ({ auth: { isAuthenticated, loading, user }, logout, loadUser }) => {
  const [isDialogOpen, toggleDialog] = useState(false);
  const [isSidebarOpen, toggleSidebar] = useState(false);

  const menu = [
    {
      label: 'Bibliothek',
      icon: 'fas fa-book',
      items: [
        {
          label: 'Organigramm',
          icon: 'fas fa-sitemap',
          url: '/organigramm'
        },
        {
          label: 'Einsatzberichte',
          icon: 'fas fa-file-medical fa-lg',
          url: '/einsatzberichte',
          disabled: user && !user.rechte.includes(READ_EINSATZBERICHT)
        },
        {
          label: 'Dateiverwaltung',
          icon: 'fas fa-folder',
          command: () => window.open('http://cloud.drk-paderborn.org/', '_blank')
        },
        {
          label: 'Newsletter',
          icon: 'far fa-newspaper',
          url: '/newsletter'
        },
        {
          label: 'Kummerkasten',
          icon: 'fas fa-theater-masks',
          url: '/kummerkasten'
        },
        {
          label: 'DRK-Server',
          icon: 'fas fa-external-link-alt',
          command: () => window.open('https://www.drkserver.org/', '_blank')
        }
      ]
    },
    {
      label: 'Kontakte',
      icon: 'fas fa-users',
      url: '/kontakte'
    },
    {
      label: 'Kalender',
      icon: 'fas fa-calendar-alt',
      url: '/kalender'
    },
    {
      label: 'Fahrzeugbelegungen',
      icon: 'fas fa-ambulance',
      url: '/fahrzeugbelegungen',
      disabled: user && !user.rechte.includes(READ_FAHRZEUGBELEGUNG)
    },
    {
      label: 'Raumbelegungen',
      icon: 'fas fa-building',
      url: '/raumbelegungen',
      disabled: user && !user.rechte.includes(READ_RAUMBELEGUNG)
    },
    {
      label: 'Dienstabende',
      icon: 'fas fa-band-aid',
      url: '/dienstabende',
      disabled: user && !user.rechte.includes(READ_DIENSTABEND)
    },
    {
      label: 'Veranstaltungen',
      icon: 'fas fa-glass-cheers',
      url: '/veranstaltungen',
      disabled: user && !user.rechte.includes(READ_VERANSTALTUNG)
    },
    {
      label: 'Sanitätsdienste',
      icon: 'fas fa-user-nurse fa-lg',
      url: '/sanitaetsdienste',
      disabled: user && !user.rechte.includes(READ_SANDIENST)
    },
    {
      label: 'Blutspende',
      icon: 'fas fa-tint',
      url: '/blutspenden',
      disabled: user && !user.rechte.includes(READ_BLUTSPENDE)
    },
    {
      label: 'Ausbildungen',
      icon: 'fas fa-school',
      url: '/ausbildung',
      disabled: user && !user.rechte.includes(READ_AUSBILDUNG)
    },
    {
      label: 'KTW',
      icon: 'fas fa-ambulance',
      items: [
        {
          label: 'Wochen KTW',
          url: '/ktw/woche',
          disabled: user && !user.rechte.includes(READ_KTW_WOCHE)
        },
        {
          label: 'Krankenhäuser',
          icon: 'fas fa-external-link-alt',
          command: () => window.open('http://ktw.drk-paderborn.org/', '_blank')
        },
        {
          label: 'Anweisungen',
          icon: 'fas fa-external-link-alt',
          command: () => window.open('http://ktw.drk-paderborn.org/anweisungen/', '_blank')
        }
      ]
    },
    {
      label: 'Kleiderkammer',
      icon: 'fas fa-tshirt',
      url: '/kleiderkammer',
      disabled: user && !user.rechte.includes(READ_KLEIDUNG)
    },
    {
      label: user && user.vorname + ' ' + user.nachname,
      icon: 'fas fa-user',
      url: '/profiles/myProfile'
    },
    { separator: true },
    {
      label: 'Infos',
      icon: 'fas fa-info-circle',
      command: () => {
        toggleDialog(true);
        toggleSidebar(false);
      }
    },
    {
      label: 'Logout',
      icon: 'fas fa-sign-out-alt',
      command: () => {
        logout();
        toggleSidebar(false);
      }
    }
  ];

  user &&
    user.rechte.includes(ABRECHNUNG) &&
    menu.splice(12, 0, {
      label: 'Abrechnung',
      icon: 'fas fa-coins',
      url: '/abrechnung'
    });

  user &&
    (user.rechte.includes(CREATE_USER) ||
      user.rechte.includes(READ_FAHRZEUGEINWEISUNG) ||
      user.rechte.includes(READ_RDF) ||
      user.rechte.includes(READ_ORT) ||
      user.rechte.includes(READ_ADMIN_LEHRGANG) ||
      user.rechte.includes(READ_FAHRZEUG) ||
      user.rechte.includes(READ_MELDER) ||
      user.rechte.includes(READ_SCHLUESSEL) ||
      user.rechte.includes(READ_USER_RECHTE) ||
      user.rechte.includes(READ_STUNDENNACHWEISE) ||
      user.rechte.includes(READ_STUNDEN_AUSWERTUNG)) &&
    menu.splice(user.rechte.includes(ABRECHNUNG) ? 13 : 12, 0, {
      label: 'Admin Tools',
      icon: 'fas fa-tools',
      items: [
        {
          label: 'User registrieren',
          icon: 'fas fa-user-plus',
          url: '/users/register',
          disabled: user && !user.rechte.includes(CREATE_USER)
        },
        {
          label: 'Fahrzeugeinweisungen',
          icon: 'fas fa-ambulance',
          url: '/fahrzeugeinweisungen',
          className: 'is-13px',
          disabled: user && !user.rechte.includes(READ_FAHRZEUGEINWEISUNG)
        },
        {
          label: 'RDF',
          icon: 'fas fa-book-medical',
          url: '/rettungsdienstfortbildungen',
          disabled: user && !user.rechte.includes(READ_RDF)
        },
        {
          label: 'Orte',
          icon: 'fas fa-map-marked-alt',
          url: '/orte',
          disabled: user && !user.rechte.includes(READ_ORT)
        },
        {
          label: 'Lehrgänge',
          icon: 'fas fa-school',
          url: '/lehrgaenge',
          disabled: user && !user.rechte.includes(READ_ADMIN_LEHRGANG)
        },
        {
          label: 'Fahrzeuge',
          icon: 'fas fa-ambulance',
          url: '/fahrzeuge',
          disabled: user && !user.rechte.includes(READ_FAHRZEUG)
        },
        {
          label: 'Melder',
          icon: 'fas fa-pager',
          url: '/melder',
          disabled: user && !user.rechte.includes(READ_MELDER)
        },
        {
          label: 'Schlüssel',
          icon: 'fas fa-key',
          url: '/schluessel',
          disabled: user && !user.rechte.includes(READ_SCHLUESSEL)
        },
        {
          label: 'Rechte',
          icon: 'fas fa-balance-scale',
          url: '/rechte',
          disabled: user && !user.rechte.includes(READ_USER_RECHTE)
        },
        {
          label: 'Stundennachweise',
          icon: 'fas fa-user-clock',
          url: '/stundennachweise',
          disabled: user && !user.rechte.includes(READ_STUNDENNACHWEISE)
        },
        {
          label: 'Stundenauswertung',
          icon: 'fas fa-chart-bar',
          url: '/auswertung',
          disabled: user && !user.rechte.includes(READ_STUNDEN_AUSWERTUNG)
        }
      ]
    });

  return (
    !loading &&
    isAuthenticated && (
      <section className='hero is-dark'>
        <div className='hero-body'>
          <div className='p-grid'>
            <div className='p-col-10'>
              <h1 className='title'>
                <Link
                  className='has-text-white'
                  to={!loading && isAuthenticated ? '/dashboard' : '/'}
                  onClick={() => loadUser()}
                >
                  <i className='fas fa-laptop-medical'></i> Intranet DRK OV PB
                </Link>
              </h1>
            </div>
            <div className='p-col-2'>
              <Button
                icon='fas fa-bars'
                className='is-pulled-right menu-button'
                onClick={() => toggleSidebar(!isSidebarOpen)}
              />
            </div>
          </div>
        </div>
        <Sidebar onHide={() => toggleSidebar(false)} visible={isSidebarOpen} position='right'>
          <SlideMenu model={menu} backLabel='Zurück' />
        </Sidebar>
        <Dialog
          header={
            <Fragment>
              <i className='fas fa-info-circle'></i> Informationen
            </Fragment>
          }
          onHide={() => toggleDialog(false)}
          visible={isDialogOpen}
        >
          <Info toggleDialog={toggleDialog} />
        </Dialog>
      </section>
    )
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  loadUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout, loadUser })(Header);
