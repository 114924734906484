import {
  GET_STUNDENNACHWEIS,
  GET_STUNDENNACHWEISE,
  STUNDENNACHWEIS_ERROR,
  STUNDENNACHWEIS_DELETED
} from '../actions/types';

const initalState = {
  stundennachweise: [],
  stundennachweis: null,
  loading: true
};

export default function (state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STUNDENNACHWEISE:
      return {
        ...state,
        stundennachweise: payload,
        loading: false
      };
    case GET_STUNDENNACHWEIS:
      return {
        ...state,
        stundennachweis: payload,
        loading: false
      };
    case STUNDENNACHWEIS_ERROR:
    case STUNDENNACHWEIS_DELETED:
    default:
      return state;
  }
}
