import { GET_FAHRZEUG, GET_FAHRZEUGE, FAHRZEUG_ERROR, FAHRZEUG_DELETED, FAHRZEUG_REAKTIVIERT } from '../actions/types';

const initialState = {
  fahrzeug: null,
  fahrzeuge: [],
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FAHRZEUG:
      return {
        ...state,
        fahrzeug: payload,
        loading: false
      };
    case GET_FAHRZEUGE:
      return {
        ...state,
        fahrzeuge: payload,
        loading: false
      };
    case FAHRZEUG_ERROR:
      return {
        ...state,
        fahrzeug: null,
        fahrzeuge: [],
        loading: false
      };
    case FAHRZEUG_DELETED:
    case FAHRZEUG_REAKTIVIERT:
    default:
      return state;
  }
}
