import { GET_RAUMBELEGUNG, GET_RAUMBELEGUNGEN, RAUMBELEGUNG_ERROR } from '../actions/types';

const initialState = {
  raumbelegungen: [],
  raumbelegung: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_RAUMBELEGUNG:
      return {
        ...state,
        raumbelegung: payload,
        loading: false
      };
    case GET_RAUMBELEGUNGEN:
      return {
        ...state,
        raumbelegungen: payload,
        loading: false
      };
    case RAUMBELEGUNG_ERROR:
      return {
        ...state,
        raumbelegungen: [],
        raumbelegung: null,
        loading: false
      };
    default:
      return state;
  }
}
