import axios from 'axios';
import { GET_NODE_VERSION, LETZTER_SAN_LEITER, LETZTER_BLUTSPENDE_LEITER } from './types';

export const getNodeVersion = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/config/node');

    dispatch({
      type: GET_NODE_VERSION,
      payload: res.data
    });
  } catch (error) {}
};

export const getLetzterSanLeiter = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/config/letzterSanLeiter');

    dispatch({
      type: LETZTER_SAN_LEITER,
      payload: res.data.wert
    });
  } catch (error) {}
};

export const getLetzterBlutspendeLeiter = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/config/letzterBlutspendeLeiter');

    dispatch({
      type: LETZTER_BLUTSPENDE_LEITER,
      payload: res.data.wert
    });
  } catch (error) {}
};
