import axios from 'axios';
import { setAlert } from './alert';
import {
  GET_PROFILE,
  PROFILE_ERROR,
  GET_PROFILES,
  CLEAR_PROFILE,
  UPDATE_PROFILE,
  ACCOUNT_DELETED,
  GET_EINWEISUNG
} from './types';
import { loadUser } from './auth';

export const getCurrentProfile = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/profile/me');

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const getProfiles = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  try {
    const res = await axios.get('/api/profile');

    dispatch({
      type: GET_PROFILES,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const getProfileById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/profile/${id}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const getProfileByUserId = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/profile/user/${userId}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const createProfile = (formData, history) => async (dispatch) => {
  try {
    await axios.post('/api/profile', formData);
    history.push('/kontakte');
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const updateProfile = (formData, history) => async (dispatch) => {
  try {
    const res = await axios.put('/api/profile', formData);

    dispatch(loadUser());
    dispatch(setAlert('Profil erfolgreich geändert', 'success'));
    history.push(`/profile/${res.data}`);
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const deleteAccount = (userId) => async (dispatch) => {
  try {
    await axios.delete(`api/profile/${userId}`);

    dispatch({
      type: ACCOUNT_DELETED
    });

    dispatch(setAlert('Account wurde gelöscht', 'success'));
    dispatch(getProfiles());
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: error.response.statusText,
        status: error.response.status
      }
    });
  }
};

export const addAusbildung = (formData, history) => async (dispatch) => {
  try {
    if (formData.ende < formData.beginn) {
      dispatch(setAlert('Enddatum kann nicht vor dem Beginndatum liegen', 'error'));
    } else {
      const res = await axios.put('/api/profile/ausbildung', formData);

      dispatch(setAlert('Ausbildung hinzugefügt', 'success'));
      dispatch(getProfileById(res.data._id));
      history.push(`/profile/${res.data._id}`);
    }
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const deleteAusbildung = (id, profileUserId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/profile/${profileUserId}/ausbildung/${id}`);

    dispatch(setAlert('Ausbildung gelöscht', 'success'));
    dispatch(getProfileById(res.data._id));
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const getEinweisungen = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/profile/einweisungen');

    dispatch({
      type: GET_PROFILES,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const getEinweisungById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/profile/einweisung/${id}`);

    dispatch({
      type: GET_EINWEISUNG,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const addEinweisung = (formData, history) => async (dispatch) => {
  try {
    const res = await axios.put('/api/profile/einweisung', formData);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Einweisung hinzugefügt', 'success'));

    history.push('/fahrzeugeinweisungen');
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const updateEinweisung = (formData, history) => async (dispatch) => {
  try {
    await axios.put(`/api/profile/einweisung/${formData.id}`, formData);

    history.push('/fahrzeugeinweisungen');
    dispatch(setAlert('Einweisung geändert', 'success'));
    dispatch(getEinweisungen());
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const deleteEinweisung = (profileId, einweisungId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/profile/${profileId}/einweisung/${einweisungId}`);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Einweisung gelöscht', 'success'));
    dispatch(getEinweisungen());
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const addRDF = (formData, history) => async (dispatch) => {
  try {
    await axios.put('/api/profile/rdf', formData);

    dispatch(setAlert('RDF hinzugefügt', 'success'));

    history.push('/dashboard');
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const updateRDF = (formData) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/profile/rdf/${formData.id}`, formData);

    dispatch(setAlert('RDF geändert', 'success'));
    dispatch(getProfileById(res.data._id));
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

export const deleteRDF = (id, profileUserId) => async (dispatch) => {
  try {
    await axios.delete(`api/profile/${profileUserId}/rdf/${id}`);

    dispatch(setAlert('RDF gelöscht', 'success'));
    dispatch(getProfileByUserId(profileUserId));
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};
