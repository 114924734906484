import {
  GET_SANITAETSDIENSTE,
  GET_SANITAETSDIENSTE_EXTERN,
  GET_SANITAETSDIENST,
  SANITAETSDIENST_DELETED,
  SANITAETSDIENST_ERROR,
  GET_SANITAETSDIENSTE_DISTINCT,
  GET_SANITAETSDIENSTE_DISTINCT_INTERN,
  GET_SANITAETSDIENSTE_DISTINCT_EXTERN
} from '../actions/types';

const initialState = {
  sanitaetsdienste: [],
  sanitaetsdienste_extern: [],
  sanitaetsdienste_distinct: [],
  sanitaetsdienste_distinct_intern: [],
  sanitaetsdienste_distinct_extern: [],
  sanitaetsdienst: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SANITAETSDIENSTE:
      return {
        ...state,
        sanitaetsdienste: payload,
        loading: false
      };
    case GET_SANITAETSDIENSTE_EXTERN:
      return {
        ...state,
        sanitaetsdienste_extern: payload,
        loading: false
      };
    case GET_SANITAETSDIENSTE_DISTINCT:
      return {
        ...state,
        sanitaetsdienste_distinct: payload,
        loading: false
      };
    case GET_SANITAETSDIENSTE_DISTINCT_INTERN:
      return {
        ...state,
        sanitaetsdienste_distinct_intern: payload,
        loading: false
      };
    case GET_SANITAETSDIENSTE_DISTINCT_EXTERN:
      return {
        ...state,
        sanitaetsdienste_distinct_extern: payload,
        loading: false
      };
    case GET_SANITAETSDIENST:
      return {
        ...state,
        sanitaetsdienst: payload,
        loading: false
      };
    case SANITAETSDIENST_DELETED:
    case SANITAETSDIENST_ERROR:
    default:
      return {
        ...state,
        loading: false
      };
  }
}
