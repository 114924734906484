import {
  GET_BLUTSPENDEN,
  GET_BLUTSPENDE,
  BLUTSPENDE_ERROR,
  BLUTSPENDE_DELETED,
  GET_BLUTSPENDEN_DISTINCT
} from '../actions/types';

const initialState = {
  blutspenden: [],
  blutspenden_distinct: [],
  blutspende: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BLUTSPENDEN:
      return {
        ...state,
        blutspenden: payload,
        loading: false
      };
    case GET_BLUTSPENDE:
      return {
        ...state,
        blutspende: payload,
        loading: false
      };
    case GET_BLUTSPENDEN_DISTINCT:
      return {
        ...state,
        blutspenden_distinct: payload,
        loading: false
      };
    case BLUTSPENDE_ERROR:
      return {
        ...state,
        loading: false
      };
    case BLUTSPENDE_DELETED:
    default:
      return state;
  }
}
