import { GET_SCHLUESSELS, GET_SCHLUESSEL, SCHLUESSEL_DELETED, SCHLUESSEL_ERROR } from '../actions/types';

const initialState = {
  schluessels: [],
  schluessel: null,
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SCHLUESSELS:
      return {
        ...state,
        schluessels: payload,
        loading: false
      };
    case GET_SCHLUESSEL:
      return {
        ...state,
        schluessel: payload,
        loading: false
      };
    case SCHLUESSEL_DELETED:
      return {
        ...state,
        loading: false
      };
    case SCHLUESSEL_ERROR:
    default:
      return state;
  }
}
