import { GET_KLEIDUNGEN, GET_KLEIDUNG, KLEIDUNG_DELETED, KLEIDUNG_ERROR } from '../actions/types';

const initialState = {
  kleidung: null,
  kleidungen: [],
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_KLEIDUNGEN:
      return {
        ...state,
        kleidungen: payload,
        loading: false
      };
    case GET_KLEIDUNG:
      return {
        ...state,
        kleidung: payload,
        loading: false
      };
    case KLEIDUNG_DELETED:
      return {
        ...state,
        loading: false
      };
    case KLEIDUNG_ERROR:
    default:
      return state;
  }
}
