export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const PASSWORT_CHANGED = 'PASSWORT_CHANGED';
export const USER_ERROR = 'USER_ERROR';
export const USER_UPDATE = 'USER_UPDATE';

export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_EINWEISUNG = 'GET_EINWEISUNG';

export const GET_STUNDENNACHWEISE = 'GET_STUNDENNACHWEISE';
export const GET_STUNDENNACHWEIS = 'GET_STUNDENNACHWEIS';
export const STUNDENNACHWEIS_ERROR = 'STUNDENNACHWEIS_ERROR';
export const STUNDENNACHWEIS_DELETED = 'STUNDENNACHWEIS_DELETED';

export const GET_LEHRGAENGE = 'GET_LEHRGAENGE';
export const GET_LEHRGANG = 'GET_LEHRGANG';
export const LEHRGANG_ERROR = 'LEHRGANG_ERROR';
export const LEHRGANG_DELETED = 'LEHRGANG_DELETED';
export const LEHRGANG_REAKTIVIERT = 'LEHRGANG_REAKTIVIERT';

export const GET_ORTE = 'GET_ORTE';
export const GET_ORT = 'GET_ORT';
export const ORT_ERROR = 'ORT_ERROR';
export const ORT_DELETED = 'ORT_DELETED';

export const GET_FAHRZEUGE = 'GET_FAHRZEUGE';
export const GET_FAHRZEUG = 'GET_FAHRZEUG';
export const FAHRZEUG_ERROR = 'FAHRZEUG_ERROR';
export const FAHRZEUG_DELETED = 'FAHRZEUG_DELETED';
export const FAHRZEUG_REAKTIVIERT = 'FAHRZEUG_REAKTIVIERT';

export const GET_FAHRZEUGBELEGUNGEN = 'GET_FAHRZEUGBELEGUNGEN';
export const GET_FAHRZEUGBELEGUNG = 'GET_FAHRZEUGBELEGUNG';
export const FAHRZEUGBELEGUNG_ERROR = 'FAHRZEUGBELEGUNG_ERROR';
export const FAHRZEUGBELEGUNG_DELETED = 'FAHRZEUGBELEGUNG_DELETED';

export const GET_RAUMBELEGUNGEN = 'GET_RAUMBELEGUNGEN';
export const GET_RAUMBELEGUNG = 'GET_RAUMBELEGUNG';
export const RAUMBELEGUNG_ERROR = 'RAUMBELEGUNG_ERROR';
export const RAUMBELEGUNG_DELETED = 'RAUMBELEGUNG_DELETED';

export const GET_SONNTAG_KTWS = 'GET_SONNTAG_KTWS';
export const GET_SONNTAG_KTW = 'GET_SONNTAG_KTW';
export const SONNTAG_KTW_ERROR = 'SONNTAG_KTW_ERROR';
export const SONNTAG_KTW_DELETED = 'SONNTAG_KTW_DELETED';

export const GET_KTW_WOCHEN = 'GET_KTW_WOCHEN';
export const GET_KTW_WOCHE = 'GET_KTW_WOCHE';
export const GET_14_KTW_01 = 'GET_14_KTW_01';
export const GET_14_KTW_02 = 'GET_14_KTW_02';
export const WOCHEN_KTW_ERROR = 'WOCHEN_KTW_ERROR';
export const WOCHEN_KTW_DELETED = 'WOCHEN_KTW_DELETED';

export const GET_SANITAETSDIENSTE = 'GET_SANITAETSDIENSTE';
export const GET_SANITAETSDIENSTE_EXTERN = 'GET_SANITAETSDIENSTE_EXTERN';
export const GET_SANITAETSDIENSTE_DISTINCT = 'GET_SANITAETSDIENSTE_DISTINCT';
export const GET_SANITAETSDIENSTE_DISTINCT_INTERN = 'GET_SANITAETSDIENSTE_DISTINCT_INTERN';
export const GET_SANITAETSDIENSTE_DISTINCT_EXTERN = 'GET_SANITAETSDIENSTE_DISTINCT_EXTERN';
export const GET_SANITAETSDIENST = 'GET_SANITAETSDIENST';
export const SANITAETSDIENST_ERROR = 'SANITAETSDIENST_ERROR';
export const SANITAETSDIENST_DELETED = 'SANITAETSDIENST_DELETED';
export const LETZTER_SAN_LEITER = 'LETZTER_SAN_LEITER';

export const GET_NODE_VERSION = 'GET_NODE_VERSION';

export const GET_CHANGELOGS = 'GET_CHANGELOGS';
export const GET_CHANGELOG = 'GET_CHANGELOG';
export const CHANGELOG_DELETED = 'CHANGELOG_DELETED';
export const CHANGELOG_ERROR = 'CHANGELOG_ERROR';

export const GET_AUSBILDUNGEN = 'GET_AUSBILDUNGEN';
export const GET_AUSBILDUNG = 'GET_AUSBILDUNG';
export const AUSBILDUNG_DELETED = 'AUSBILDUNG_DELETED';
export const AUSBILDUNG_ERROR = 'AUSBILDUNG_ERROR';

export const GET_DIENSTABENDE = 'GET_DIENSTABENDE';
export const GET_DIENSTABEND = 'GET_DIENSTABEND';
export const DIENSTABEND_ERROR = 'DIENSTABEND_ERROR';
export const DIENSTABEND_DELETED = 'DIENSTABEND_DELETED';

export const GET_VERANSTALTUNGEN = 'GET_VERANSTALTUNGEN';
export const GET_VERANSTALTUNG = 'GET_VERANSTALTUNG';
export const VERANSTALTUNG_ERROR = 'VERANSTALTUNG_ERROR';
export const VERANSTALTUNG_DELETED = 'VERANSTALTUNG_DELETED';

export const GET_BLUTSPENDEN = 'GET_BLUTSPENDEN';
export const GET_BLUTSPENDEN_DISTINCT = 'GET_BLUTSPENDEN_DISTINCT';
export const GET_BLUTSPENDE = 'GET_BLUTSPENDE';
export const BLUTSPENDE_ERROR = 'BLUTSPENDE_ERROR';
export const BLUTSPENDE_DELETED = 'BLUTSPENDE_DELETED';
export const LETZTER_BLUTSPENDE_LEITER = 'LETZTER_BLUTSPENDE_LEITER';

export const GET_EINSATZBERICHTE = 'GET_EINSATZBERICHTE';
export const GET_EINSATZBERICHT = 'GET_EINSATZBERICHT';
export const EINSATZBERICHT_ERROR = 'EINSATZBERICHT_ERROR';
export const EINSATZBERICHT_DELETED = 'EINSATZBERICHT_DELETED';

export const GET_MELDERS = 'GET_MELDERS';
export const GET_MELDER = 'GET_MELDER';
export const MELDER_ERROR = 'MELDER_ERROR';
export const MELDER_DELETED = 'MELDER_DELETED';

export const GET_SCHLUESSELS = 'GET_SCHLUESSELS';
export const GET_SCHLUESSEL = 'GET_SCHLUESSEL';
export const SCHLUESSEL_ERROR = 'SCHLUESSEL_ERROR';
export const SCHLUESSEL_DELETED = 'SCHLUESSEL_DELETED';

export const GET_KLEIDUNGEN = 'GET_KLEIDUNGEN';
export const GET_KLEIDUNG = 'GET_KLEIDUNG';
export const KLEIDUNG_ERROR = 'KLEIDUNG_ERROR';
export const KLEIDUNG_DELETED = 'KLEIDUNG_DELETED';

export const GET_RETTUNGSDIENSTFORTBILDUNGEN = 'GET_RETTUNGSDIENSTFORTBILDUNGEN';
export const GET_RETTUNGSDIENSTFORTBILDUNG = 'GET_RETTUNGSDIENSTFORTBILDUNG';
export const RETTUNGSDIENSTFORTBILDUNG_ERROR = 'RETTUNGSDIENSTFORTBILDUNG_ERROR';
export const RETTUNGSDIENSTFORTBILDUNG_DELETED = 'RETTUNGSDIENSTFORTBILDUNG_DELETED';

export const GET_NEWSLETTERS = 'GET_NEWSLETTERS';
export const GET_NEWSLETTER = 'GET_NEWSLETTER';
export const NEWSLETTER_DELETED = 'NEWSLETTER_DELETED';
export const NEWSLETTER_ERROR = 'NEWSLETTER_ERROR';
