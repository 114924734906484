import { GET_SONNTAG_KTWS, SONNTAG_KTW_ERROR, SONNTAG_KTW_DELETED, GET_SONNTAG_KTW } from '../actions/types';

const initialState = {
  sonntagKTW: null,
  sonntagKTWs: [],
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SONNTAG_KTWS:
      return {
        ...state,
        sonntagKTWs: payload,
        loading: false
      };
    case GET_SONNTAG_KTW:
      return {
        ...state,
        sonntagKTW: payload,
        loading: false
      };
    case SONNTAG_KTW_ERROR:
    case SONNTAG_KTW_DELETED:
    default:
      return {
        ...state,
        loading: false
      };
  }
}
