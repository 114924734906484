import { GET_ORT, GET_ORTE, ORT_ERROR } from '../actions/types';

const initialState = {
  ort: null,
  orte: [],
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ORT:
      return {
        ...state,
        ort: payload,
        loading: false
      };
    case GET_ORTE:
      return {
        ...state,
        orte: payload,
        loading: false
      };
    case ORT_ERROR:
      return {
        ...state,
        ort: null,
        orte: [],
        loading: false
      };
    default:
      return state;
  }
}
